import React, { useState, useEffect } from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import { Link, graphql, useStaticQuery, } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import starIcon from '../assets/images/star-icon.png'

const query = graphql` 
    {
        allStrapiProjects(sort: {fields: id, order: DESC}) {
            edges {
              node {
                slug
                project_name
                project_title
                project_type
                website
                technologies
                location
                content
                client
                project_img {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                AppIcon {
                  localFile {
                    childImageSharp {
                      original {
                        src
                      }
                    }
                  }
                }
              }
            }
            distinct(field: project_type)
          }
    }
`

function Work() {

    const data = useStaticQuery(query)

    const [projects, setProjects] = useState([...data.allStrapiProjects.edges])
    // const projects = data.allStrapiProjects.edges
    const project_types = data.allStrapiProjects.distinct

    const [filter, setFilter] = useState("All")
    console.log('filter', filter);

    console.log("projects", projects)

    useEffect(() => {
        if (filter === "All") {
            setProjects([...data.allStrapiProjects.edges])
        } else {
            setProjects([...data.allStrapiProjects.edges.filter(i => i.node.project_type === filter)])
        }
    }, [filter])


    // var elem = document.getElementsByClassName('btn-orange');

    // for (let i = 0; i < elem.length; i++) {
    //   elem[i].classList.remove("active");
    // }

    // // positionSection.classList.add("active");
    // if (document.getElementsByClassName(filter).length > 0){
    //     document.getElementsByClassName(filter)[0].classList.add("active");
    // } else{
    //     if(document.getElementsByClassName("all").length > 0){
    //     document.getElementsByClassName("all")[0].classList.add("active");
    //     }
    // }


    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Projects"
                homePageText="Home"
                homePageUrl="/"
                activePageText="Work"
            />

            <section id='main-div' className="projects-area bg-color pt-50 pb-70 ">
                <div className="container">
                    <div className="section-title">
                        {/* <span className="sub-title">
                                    <img src={starIcon} alt="project" /> Recent Projects
                                </span> */}
                        <h2>Check Some Of Our Recent Work</h2>
                        <p className='section-parag'>Explore how Srashtasoft shaped multiple businesses with innovation and technology enabled solutions. Take a look at our case-studies.</p>
                    </div>
                    <div className="button-box filtter-button">
                        <button className={filter === "All" ? `btn-orange all active` : `btn-orange all`} onClick={() => setFilter("All")}>All</button>
                        {project_types.map(t => (
                            <button className={filter === t ? `btn-orange ${t} active` : `btn-orange ${t}`} onClick={() => setFilter(t)}>{t}</button>
                        ))}
                    </div>
                    <div className="row">

                        {projects.map((project, i) =>
                        (
                            <div key={i} className="col-lg-6 col-md-6">
                                <div className="single-projects-box">
                                    <div className="image work-height">
                                        <GatsbyImage image={getImage(project.node.project_img.localFile)} alt="project" />

                                        <Link className="link-btn" to={`/project/${project.node.slug}/`}>
                                            <i className='bx bx-plus'></i>
                                        </Link>
                                    </div>

                                    <div className="content">
                                        <h3>
                                            <Link to={`/project/${project.node.slug}/`}>
                                                {project.node.project_name}
                                            </Link>
                                            <p>{project.node.project_title}</p>
                                        </h3>
                                        <span>{project.node.project_type}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {/* <div className="row">
                            <ul class="pagination pagination-lg">
    <li class="page-item"><a class="page-link" href="#">Previous</a></li>
    <li class="page-item"><a class="page-link" href="#">1</a></li>
    <li class="page-item"><a class="page-link " href="#">2</a></li>
    <li class="page-item"><a class="page-link" href="#">Next</a></li>
  </ul> 
                            </div> */}
                </div>
            </section>

            <Footer />
        </Layout>
    )
}

export default Work
